<template>
	<div id="userList">
		<div class="el-content">
			<a-space>
				<a-input-search v-model:value="name" style="width: 300px" placeholder="姓名" enter-button @search="getUserList(1, info.limit)" />
				<a-button type="primary" v-has="{ action: 'sass_agency_add' }" @click="show.addOrEdit = true">
					<i class="ri-add-line ri-top"></i>
					添加代理商
				</a-button>
			</a-space>
		</div>
		<div class="el-content">
			<a-table :pagination="false" row-key="user_id" :data-source="info.list" :columns="[
				{title:'ID',dataIndex:'user_id'},
				{title:'手机号',dataIndex:'mobile'},
				{title:'用户名',dataIndex:'name'},
				{title:'城市',dataIndex:'address'},
				{title:'可创建小程序(个)',dataIndex:'company.limit'},
				{title:'状态',dataIndex:'status', slots: { customRender: 'status' }},
				{title:'创建时间',dataIndex:'create_time'},
				{title:'最后登录时间',dataIndex:'login_time'},
				{title:'最后登录IP',dataIndex:'last_login_ip'},
				{title:'操作',dataIndex:'action', slots: { customRender: 'action' }},
			]" >
				<template #status="{ record }">
					<a-tag :color="record.status == 1 ? '#00CC66':'#FF6633'">{{ record.status ? "正常":"已禁用" }}</a-tag>
				</template>
				<template #action="{ record }">
					<a-button v-has="{action:'sass_agency_employee'}" @click="showEmployeeList(record.company.company_id)" type="link" size="small">查看员工</a-button>
					<a-button v-has="{action:'sass_agency_user'}" @click="showUserList(record.company.company_id)" type="link" size="small">查看用户</a-button>
					<router-link :to="{path:'/admin/agency/agency_info',query:{user_id:record.user_id}}">
						<a-button v-has="{action:'/admin/agency/agency_info'}" type="link" size="small">详情</a-button>
					</router-link>
					<a-button v-has="{action:'sass_agency_stop'}" type="link" @click="deletUser(record)" size="small">{{record.status ==1 ? '禁用':'启用'}}</a-button>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination 
					show-size-changer 
					v-model:current="info.page" 
					v-model:pageSize="info.limit" 
					:total="info.count" 
					@showSizeChange="(p,e)=>{getUserList(info.page,e)}"
					@change="(e)=>getUserList(e,info.limit)"
				/>
			</div>
		</div>
		<a-modal v-model:visible="show.addOrEdit" title="添加编辑代理商" width="700px"  @ok="createOrUpdateAgent" @cancel="show.addOrEdit = false">
			<a-form :label-col="{span:4}" :wrapper-col="{span:18}">
				<a-form-item label="昵称">
					<a-input v-model:value="form.name"></a-input>
				</a-form-item>
				<a-form-item label="手机号">
					<a-input v-model:value="form.mobile"></a-input>
				</a-form-item>
				<a-form-item label="密码" v-if="!form.user_id">
					<a-input v-model:value="form.password"></a-input>
				</a-form-item>
				<a-form-item label="地址">
					<a-input v-model:value="form.address"></a-input>
				</a-form-item>
				<a-form-item label="永久授权">
					<a-radio-group v-model:value="form.is_permanent">
						<a-radio :value="1">开启</a-radio>
						<a-radio :value="0">关闭</a-radio>
					</a-radio-group>
				</a-form-item>
				<a-form-item label="授权到期时间">
					<a-date-picker v-model:value="form.validity_time" format="YYYY-MM-DD"/>
				</a-form-item>
			</a-form>
		</a-modal>
		<a-modal v-model:visible="show.employee" title="员工" width="900px" @cancel="show.addOrEdit = false">
			<a-table size="small" :pagination="false" row-key="user_id" :data-source="employee.list" :columns="[
					{ title: 'ID', dataIndex: 'user_id' },
					{ title: '手机号', dataIndex: 'mobile' },
					{ title: '用户名', dataIndex: 'name' },
					{ title: '最后登录时间', dataIndex: 'login_time' },
					{ title: '最后登录IP', dataIndex: 'last_login_ip' }
				]"
			></a-table>
			<div class="pager">
				<a-pagination
					show-size-changer
					v-model:current="employee.page"
					v-model:pageSize="employee.limit"
					:total="employee.count"
					@showSizeChange="(p, e) => { getEmployeeList(employee.page, e); } "
					@change="e => getEmployeeList(e, employee.limit)"
				/>
			</div>
		</a-modal>
		<a-modal v-model:visible="show.user" title="用户" width="900px" @cancel="show.addOrEdit = false">
			<a-table :pagination="false" row-key="user_id" :data-source="agentUser.list" :columns="[
					{ title: 'ID', dataIndex: 'user_id' },
					{ title: '手机号', dataIndex: 'mobile' },
					{ title: '用户名', dataIndex: 'name' },
					{ title: '最后登录时间', dataIndex: 'login_time' },
					{ title: '最后登录IP', dataIndex: 'last_login_ip' }
				]"
			></a-table>
			<div class="pager">
				<a-pagination
					show-size-changer
					v-model:current="agentUser.page"
					v-model:pageSize="agentUser.limit"
					:total="agentUser.count"
					@showSizeChange=" (p, e) => { getAgentUserList(agentUser.page, e); } "
					@change="e => getAgentUserList(e, agentUser.limit)"
				/>
			</div>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import saasUserModel from '@/api/saas/user'
import authModel from '@/api/saas/auth';
export default {
	setup(){
		const state = reactive({
			info: {
				list: [],
				page: 1,
				count: 0,
				limit: 10
			},
			name: '',
			user_id: 0,
			company_id: 0,
			show: {
				addOrEdit: false,
				employee: false,
				user: false,
				password:false,
			},
			employee:{
				list:[],
				page:1,
				limit:10,
				id:0,
			},
			agentUser:{
				list:[],
				page:1,
				limit:10,
				id:0,
			},
			form: {
				name: '',
				mobile: '',
				password: '',
				is_permanent: 0,
				validity_time: '',
				address: ''
			},
			passwordForm:{
				password:"",
				re_password:"",
				user_id: 0,
			}
		})
		getUserList(1,state.info.limit)

		function getUserList(page,limit){
			saasUserModel.getAgentUser(page,limit,{name:state.name},res=>state.info = {limit,...res})
		}

		const deletUser = row =>saasUserModel.deleteUser(row.user_id,row.status,()=>{
			getUserList(state.info.page,state.info.limit)
		})

		const createOrUpdateAgent = ()=>saasUserModel.addOrEditAgentUser(state.form,()=>{
			getUserList(state.info.page,state.info.limit)
			state.show.addOrEdit = false;
		})

		function showEmployeeList(id){
			state.show.employee = true;
			state.employee.id = id;
			state.company_id = id;
			getEmployeeList(1,state.employee.limit)
		}


		//获取员工数据
		function getEmployeeList(page,limit){
			authModel.getEmployee(page,limit,{company_id:state.employee.id,type:1},res=>{
				state.employee.list = res.data
                state.employee.page = res.page.currentPage
                state.employee.count = res.page.totalCount
			})
		}

		function closeEmployee(e) {
			if (e == -1) {
				state.show.employee = false;
				state.user_id = 0;
			}
		}

		function showUserList(id) {
			state.show.user = true;
			state.agentUser.id = id;
			getAgentUserList(1,10)
		}

		function getAgentUserList(page,limit){
			let company_id = state.agentUser.id
			saasUserModel.getAgentUser(page,limit,{sort:2,rank:3,company_id},res=>{
				state.agentUser = { id:company_id,limit,...res}
			})
		}

		function closeUser(e) {
			if (e == -1) {
				state.show.user = false;
				state.user_id = 0;
			}
		}

		return{
			...toRefs(state),
			getUserList,
			deletUser,
			createOrUpdateAgent,
			showEmployeeList,
			closeEmployee,
			closeUser,
			showUserList,
			getEmployeeList,
			getAgentUserList
		}
	}
}
</script>

<style lang="scss"></style>
